import React from "react";
import card1 from "../../../../../Assests/recent-product/1-1.jpg";
import card2 from "../../../../../Assests/recent-product/1-2.jpg";
import card3 from "../../../../../Assests/recent-product/1-3.jpg";
import card4 from "../../../../../Assests/recent-product/1-4.jpg";
import card5 from "../../../../../Assests/recent-product/1-5.jpg";
import card6 from "../../../../../Assests/recent-product/1-6.png";
import card7 from "../../../../../Assests/recent-product/1-7.jpg";
import card8 from "../../../../../Assests/recent-product/1-8.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const RecentProduct = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="recent-product">
          <h1
            className="text-center head-color"
            data-aos="fade-right"
            data-aos-duration="3000"
          >
            Welcome To
          </h1>
          <h1
            className="text-center head-color"
            data-aos="fade-left"
            data-aos-duration="3000"
          >
            RYB Engineering
          </h1>
        </div>

        <div
          className="col-md-3 mb-4"
          data-aos="fade-right"
          data-aos-duration="3000"
        >
          <div className="card-image">
            <img src={card1} className="img-fluid card-image-inner" alt="" />
          </div>
        </div>

        <div
          className="col-md-3 mb-4"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <div className="card-image">
            <img src={card2} className="img-fluid card-image-inner" alt="" />
          </div>
        </div>

        <div
          className="col-md-3 mb-4"
          data-aos="fade-down"
          data-aos-duration="3000"
        >
          <div className="card-image">
            <img src={card3} className="img-fluid card-image-inner" alt="" />
          </div>
        </div>

        <div
          className="col-md-3 mb-4"
          data-aos="fade-left"
          data-aos-duration="3000"
        >
          <div className="card-image">
            <img src={card4} className="img-fluid card-image-inner" alt="" />
          </div>
        </div>

        <div
          className="col-md-3 mb-4"
          data-aos="fade-right"
          data-aos-duration="3000"
        >
          <div className="card-image">
            <img src={card5} className="img-fluid card-image-inner" alt="" />
          </div>
        </div>

        <div
          className="col-md-3 mb-4"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <div className="card-image">
            <img src={card6} className="img-fluid card-image-inner" alt="" />
          </div>
        </div>

        <div
          className="col-md-3 mb-4"
          data-aos="fade-down"
          data-aos-duration="3000"
        >
          <div className="card-image">
            <img src={card7} className="img-fluid card-image-inner" alt="" />
          </div>
        </div>

        <div
          className="col-md-3 mb-4"
          data-aos="fade-left"
          data-aos-duration="3000"
        >
          <div className="card-image">
            <img src={card8} className="img-fluid card-image-inner" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentProduct;
