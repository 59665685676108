import React from "react";
import img1 from "../../../../Assests/aboutUs/aboutImage1.png";
import img2 from "../../../../Assests/aboutUs/aboutImage2.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const AboutContent = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <section className="mt-3">
        <div className="container mt-5">
          <div className="row align-items-center">
            <div
              className="col-md-4 about-img "
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <img
                src={img1}
                alt="img"
                className="img-fluid about-img image-shape"
              />
            </div>
            <div className="col-md-8">
              {/*<h3>Our Vision</h3>*/}
              <div
                className="about-inner"
                data-aos="fade-left"
                data-aos-duration="3000"
              >
                <h1 className="title head-color">Our Vision</h1>
                <p>
                  We<b>"RYB Engineering"</b>wants take challenge and to be one
                  of the leading and trusted power engineering company in
                  Bangladesh by 2040.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-8">
              <div
                className="about-inner-right"
                data-aos="fade-right"
                data-aos-duration="3000"
              >
                <h1 className="title head-color">Our Mission</h1>
                <p>
                  Our mission is to build long term relationship and provide
                  quality product & services exceeding customers expectations in
                  Bangladesh. As we carry out our mission, we shall be guided by
                  values of integrity, reliability, commitment, and
                  collaboration. We will success this mission by use new
                  technology and dedicated & experienced personnel with the
                  objective of sustainable growth.
                </p>
                <p>
                  We will continue to expand in equipment and facilities,
                  implement & incorporate new technologies in{" "}
                  <strong>"RYB Engineering"</strong> for quality product and
                  services to customer.
                </p>
                <p>
                  We will meet and exceed customer expectations through our full
                  effort, quality product, commitment & services.
                </p>
              </div>
            </div>

            <div
              className="col-md-4 about-img-right "
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <img
                src={img2}
                alt="img"
                className="img-fluid about-img image-shape"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutContent;
