import React from 'react';
import './Slider.css'
import img1 from '../../../../../Assests/SliderImage/cover-1.png'
import img2 from '../../../../../Assests/SliderImage/cover-2.jpg'
import img3 from '../../../../../Assests/SliderImage/cover-3.png'
import img4 from '../../../../../Assests/SliderImage/cover-4.png'


const SliderBanner = () => {

    return (

        <div id="carouselExampleSlidesOnly" className="carousel slide custom-slide" data-bs-ride="carousel">
            <div className="carousel-inner custom-carousel-inner">
                <div className="carousel-item ">
                    <div className="image-overlay dark">
                        <img src={img1} className="w-100" alt="..."/>
                    </div>
                </div>
                <div className="carousel-item active">
                    <div className="image-overlay dark">
                        <img src={img2} className="w-100" alt="..."/>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="image-overlay dark">
                        <img src={img3} className="w-100" alt="..."/>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="image-overlay dark">
                        <img src={img4} className="w-100" alt="..."/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SliderBanner;
