import React from "react";
import { RouterProvider } from "react-router-dom";
import router from "./componet/Route/Route";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "./Assests/Css/style.css";

function App() {
  return (
    <div className="commonhiden">
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
