import React from 'react';
import SliderBanner from "./Section/Slider/SliderBanner";
import RecentProduct from "./Section/RecentProduct/RecentProduct";
import Introduction from "./Section/Introduction/Introduction";
import BackgroundPic from "./Section/BackgroundPic/BackgroundPic";
import OurClint from "./Section/OurClint/OurClint";

const Home = () => {
    return (
        <div>
            <SliderBanner></SliderBanner>
            <RecentProduct></RecentProduct>
            <Introduction></Introduction>
            {/*<BackgroundPic></BackgroundPic>*/}
            <OurClint></OurClint>
        </div>
    );
};

export default Home;
