import React from "react";
import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "../Pages/ErrorPage/ErrorPage";
import Home from "../Pages/Home/Home";
import Main from "../Layout/Main";
import About from "../Pages/About/About";
import ProductAndService from "../Pages/ProductAndService/ProductAndService";
import Contact from "../Pages/Contact/Contact";
import NewsAndDownload from "../Pages/NewsAndDownload/NewsAndDownload";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main></Main>,
    errorElement: <ErrorPage></ErrorPage>,
    children: [
      {
        path: "/",
        element: <Home></Home>,
      },
      {
        path: "/home",
        element: <Home></Home>,
      },
      { path: "/about", element: <About></About> },
      {
        path: "/product-and-service",
        element: <ProductAndService></ProductAndService>,
      },
      { path: "/contact", element: <Contact></Contact> },
      {
        path: "/news-and-download",
        element: <NewsAndDownload></NewsAndDownload>,
      },
    ],
  },
]);
export default router;
