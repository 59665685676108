import React from 'react';

const NewsBanner = () => {
    return (
        <div>
            <div className="contact-banner-section">
                <div className="contact-banner-overlay"></div>
                <div className='contact-us-title'>
                    <h1>Recent News</h1>
                </div>
            </div>
        </div>
    );
};

export default NewsBanner;