import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="container-fluid mt-5 g-0">
        <footer
          className="text-center text-lg-start text-white"
          style={{ backgroundColor: "#000000" }}
        >
          <section className="py-2">
            <div className="container text-center text-md-start mt-5">
              <div className="row mt-3">
                <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">RYB Engineering</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    Here you can use rows and columns to organize your footer
                    content. Lorem ipsum dolor sit amet, consectetur adipisicing
                    elit.
                  </p>
                  <div>
                    <Link
                      to="https://www.facebook.com/profile.php?id=100094779467329&mibextid=ZbWKwL"
                      className="text-white me-4"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                    <Link to="" className="text-white me-4">
                      <i className="fab fa-twitter"></i>
                    </Link>
                    <Link to="" className="text-white me-4">
                      <i className="fab fa-google"></i>
                    </Link>
                    <Link to="" className="text-white me-4">
                      <i className="fab fa-instagram"></i>
                    </Link>
                    <Link to="" className="text-white me-4">
                      <i className="fab fa-linkedin"></i>
                    </Link>
                    <Link to="" className="text-white me-4">
                      <i className="fab fa-github"></i>
                    </Link>
                  </div>
                </div>

                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                  <h6 className="text-uppercase fw-bold">Dhaka Office :</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />

                  <p>
                    <i className="fas fa-home mr-3"></i> House: # 30, Road: # 8,
                    Block: # F, Banasree, Dhaka-1219, Bangladesh.
                  </p>
                  <p>
                    <i className="fas fa-envelope mr-3"></i>{" "}
                    sales.dhaka@rybengineering.com
                  </p>
                  <p>
                    <i className="fas fa-phone mr-3"></i> + 88 01515602238
                  </p>
                </div>

                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                  <h6 className="text-uppercase fw-bold">
                    Chittagong Office :
                  </h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{
                      width: "60px",
                      backgroundColor: "#7c4dff",
                      height: "2px",
                    }}
                  />
                  <p>
                    <i className="fas fa-home mr-3"></i> Flat # 2/B-1, NHA
                    MEHGNA, Road # 1, Block # G, Halishahor, Chittagong,
                    bangladesh.
                  </p>
                  <p>
                    <i className="fas fa-envelope mr-3"></i>{" "}
                    sales.chattogram@rybengineering.com
                  </p>
                  <p>
                    <i className="fas fa-phone mr-3"></i> + 88 01915842284
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/*<div className="text-center p-3" style={{backgroundColor: 'rgba(33,28,28,0.2)'}}>© 2023 Copyright:*/}
          {/*    <Link className="text-white" to="https://mdbootstrap.com/">ryb@gmail.com</Link>*/}
          {/*</div>*/}
        </footer>
      </div>
      <div className="container-fluid">
        <div className="row text-center ">
          <div className="col-md-4 p-2 bg-color-1">
            <p>
              <span className="me-2">
                <i className="fa-solid fa-globe"></i>
              </span>
              www.rybengineering.com
            </p>
          </div>
          <div className="col-md-4 p-2 bg-color-2">
            <p>
              <span className="me-2">
                <i className="fas fa-phone"></i>
              </span>
              +88013 16 19 71 27
            </p>
          </div>
          <div className="col-md-4 p-2 bg-color-3">
            <p>
              <span className="me-2">
                <i className="fas fa-envelope"></i>
              </span>
              info@rybengineering.com
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
