import React from "react";
import img1 from "../../../Assests/product/1.jpg";
import img2 from "../../../Assests/product/2.jpg";
import img3 from "../../../Assests/product/3.jpg";
import img4 from "../../../Assests/product/4.jpg";
import img5 from "../../../Assests/product/5.jpg";
import img6 from "../../../Assests/product/6.jpg";
import img7 from "../../../Assests/product/7.jpg";
import img8 from "../../../Assests/product/13.jpg";
import img9 from "../../../Assests/product/9.jpg";
import img10 from "../../../Assests/product/10.jpg";
import img11 from "../../../Assests/product/11.jpg";
import img12 from "../../../Assests/product/12.jpg";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
const ProductAndService = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <div className="contact-banner-section">
        <div className="contact-banner-overlay"></div>
        {/*<div className='contact-us-title'>*/}
        {/*    <h1>Product & Service</h1>*/}
        {/*</div>*/}
      </div>
      <div className="container">
        <h1 className="text-center py-5 head-color line-after">Products</h1>
        <div className="row align-items-center product-margin">
          <div className="col-md-4">
            <div className="product-inner">
              <p>
                <i className="fa-solid fa-right-long"></i> SUBSTATION
              </p>
              <p>
                <i className="fa-solid fa-right-long"></i> GENERATOR
              </p>
              <p>
                <i className="fa-solid fa-right-long"></i>BUSBAR TRUNKING SYSTEM
                (BBT)
              </p>
              <p>
                <i className="fa-solid fa-right-long"></i> CABLE TRAY & CABLE
                LADDER
              </p>
              <p>
                <i className="fa-solid fa-right-long"></i> LIGHTNING PROTECTION
                SYSTEM (LPS)
              </p>
              <p>
                <i className="fa-solid fa-right-long"></i> ALL KINDS OF
                INDUSTRIAL LIGHTING{" "}
              </p>
              <p>
                <i className="fa-solid fa-right-long"></i> PANELS (HT, LT, ELT,
                ATS, COS, ETC.)
              </p>
              <p>
                <i className="fa-solid fa-right-long"></i> DB (MDB, SDB, ESDB,
                PDB, LDB, ELDB, ETC.)
              </p>
              <p>
                <i className="fa-solid fa-right-long"></i> MOTOR CONTROL CENTRE
                (MCC PANEL)
              </p>
              <p>
                <i className="fa-solid fa-right-long"></i> POWER FACTOR
                IMPROVEMENT PLANT (PFI PLANT)
              </p>
              <p>
                <i className="fa-solid fa-right-long"></i> LV ELECTRICAL SPARES
                SUPPLY
              </p>
              <p>
                <i className="fa-solid fa-right-long"></i> SOLAR SYSTEM
              </p>
              <p>
                <i className="fa-solid fa-right-long"></i> FIRE DETECTION AND
                PROTECTION SYSTEM
              </p>
            </div>
          </div>
          <div className="col-md-8 product-img">
            <div className="row">
              <div
                className="col-md-4 poligon"
                data-aos="fade-right"
                data-aos-duration="3000"
              >
                <img
                  src={img1}
                  alt="img"
                  className="img-fluid product-width-and-height "
                />
              </div>
              <div
                className="col-md-4 poligon"
                data-aos="zoom-in"
                data-aos-duration="3000"
              >
                <img
                  src={img2}
                  alt="img"
                  className="img-fluid product-width-and-height"
                />
              </div>
              <div
                className="col-md-4 poligon"
                data-aos="fade-left"
                data-aos-duration="3000"
              >
                <img
                  src={img3}
                  alt="img"
                  className="img-fluid product-width-and-height"
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-4 poligon"
                data-aos="fade-right"
                data-aos-duration="3000"
              >
                <img
                  src={img4}
                  alt="img"
                  className="img-fluid product-width-and-height"
                />
              </div>
              <div
                className="col-md-4 poligon"
                data-aos="zoom-in"
                data-aos-duration="3000"
              >
                <img
                  src={img5}
                  alt="img"
                  className="img-fluid product-width-and-height"
                />
              </div>
              <div
                className="col-md-4 poligon"
                data-aos="fade-left"
                data-aos-duration="3000"
              >
                <img
                  src={img6}
                  alt="img"
                  className="img-fluid product-width-and-height"
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-4 poligon"
                data-aos="fade-right"
                data-aos-duration="3000"
              >
                <img
                  src={img7}
                  alt="img"
                  className="img-fluid product-width-and-height"
                />
              </div>
              <div
                className="col-md-4 poligon"
                data-aos="zoom-in"
                data-aos-duration="3000"
              >
                <img
                  src={img8}
                  alt="img"
                  className="img-fluid product-width-and-height"
                />
              </div>
              <div
                className="col-md-4 poligon"
                data-aos="fade-left"
                data-aos-duration="3000"
              >
                <img
                  src={img9}
                  alt="img"
                  className="img-fluid product-width-and-height"
                />
              </div>
            </div>
          </div>
        </div>
        <h1 className="text-center py-5 head-color line-after">Services</h1>
        <div className="row align-items-center product-margin">
          <div className="col-md-4">
            <div className="product-inner">
              <p>
                <i className="fa-solid fa-right-long"></i> Electrical and Fire
                Safety Consultancy
              </p>
              <p>
                <i className="fa-solid fa-right-long"></i> Electrical and Fire
                Safety Design & Documentations (SLD, ELD, LPS, CFDS, CFPS, Etc.){" "}
              </p>
              <p>
                <i className="fa-solid fa-right-long"></i> Electrical and Fire
                Safety Inspection & Testing (ER, IR, Thermal, Annual Testing,
                Etc.)
              </p>
              <p>
                <i className="fa-solid fa-right-long"></i> Industrial Wiring and
                Rectification Works
              </p>
              <p>
                <i className="fa-solid fa-right-long"></i> Electrical
                Installation & Erection Works
              </p>
              <p>
                <i className="fa-solid fa-right-long"></i> Electromechanical
                Trunkey Project
              </p>
              <p>
                <i className="fa-solid fa-right-long"></i> generator overhauling
                & routine services
              </p>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div
                className="col-md-4 poligon"
                data-aos="fade-right"
                data-aos-duration="3000"
              >
                <img
                  src={img10}
                  alt="img"
                  className="img-fluid product-width-and-height"
                />
              </div>
              <div
                className="col-md-4 poligon"
                data-aos="zoom-in"
                data-aos-duration="3000"
              >
                <img
                  src={img11}
                  alt="img"
                  className="img-fluid product-width-and-height"
                />
              </div>
              <div
                className="col-md-4 poligon"
                data-aos="fade-left"
                data-aos-duration="3000"
              >
                <img
                  src={img12}
                  alt="img"
                  className="img-fluid product-width-and-height"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductAndService;
