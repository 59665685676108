import React from "react";
import news1 from "../../../Assests/News/4-1.png";
import news2 from "../../../Assests/News/4-2.jpg";
import news3 from "../../../Assests/News/4-3.jpg";
import NewsBanner from "./NewsBanner";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
const file1 = "https://rybengineering.com/We.pdf";
const file2 = "https://rybengineering.com/We.pdf";
const file3 = "https://rybengineering.com/We.pdf";

const NewsAndDownload = () => {
  const downloadFile = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <NewsBanner></NewsBanner>
      <div className="container">
        <div className="row py-5 mt-5">
          <div className="col-md-4">
            <div className="card" data-aos="zoom-in" data-aos-duration="3000">
              <img src={news1} className="card-img-top img-fluid" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Blog Title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
                <button
                  onClick={() => {
                    downloadFile(file1);
                  }}
                  className="btn download-btn"
                >
                  Download PDF
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card" data-aos="zoom-in" data-aos-duration="3000">
              <img src={news2} className="card-img-top img-fluid" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Blog Title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
                <button
                  onClick={() => {
                    downloadFile(file2);
                  }}
                  className="btn download-btn"
                >
                  Download PDF
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card" data-aos="zoom-in" data-aos-duration="3000">
              <img src={news3} className="card-img-top img-fluid" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Blog Title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
                <button
                  onClick={() => {
                    downloadFile(file3);
                  }}
                  className="btn download-btn"
                >
                  Download PDF
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsAndDownload;
