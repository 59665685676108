import React from 'react';

const AboutBanner = () => {
    return (
        <div>
            <div className="contact-banner-section">
                <div className="contact-banner-overlay"></div>
                <div className='contact-us-title'>
                    <h1>WHO WE ARE & WHAT WE DO</h1>
                </div>
            </div>
            <section>
                <div className="container">
                    <div className="row py-5">
                        <div className="col-lg-12">
                            <p>We <strong>"RYB Engineering"</strong> are very pleased to
                            introduce ourselves as a premium quality
                            Supplier, Manufacturer, Consultancy and
                            Installation & Erection Service Provider.<br/><br/>
                            All the entrepreneurs of <strong>"RYB Engineering"</strong>  are
                            young but well experienced engineers having 8-12
                            years of experience of rendering their services in
                            the power sector through renowned and leading
                            power engineering companies.<br/><br/>
                            We <strong>"RYB Engineering"</strong>  are also pleased to
                            introduce as a well equipped team of qualified &
                            experienced electrical engineers, involved in
                            power engineering sector.<br/><br/>
                            The main activity of <strong>"RYB Engineering"</strong>  is
                            Substation, Generator, Busbar Trunking System
                            (BBT), Cable Tray & Cable Ladder, Lightning
                            Protection System (LPS), Industrial Lighting, All
                            Kinds of Electrical Panels, LV Electrical Spares
                            Supply, Solar System, Electrical and Fire Safety
                            Consultancy, Fire Detection and Protection
                            System</p>
                                                </div>
                    </div>
                </div>

            </section>
        </div>
    );
};

export default AboutBanner;
