import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from '../../../../../Assests/OurClintImage/Picture1.jpg'
import img2 from '../../../../../Assests/OurClintImage/Picture2.png'
import img3 from '../../../../../Assests/OurClintImage/Picture3.jpg'
import img4 from '../../../../../Assests/OurClintImage/Picture4.png'
import img5 from '../../../../../Assests/OurClintImage/Picture5.jpg'
import img6 from '../../../../../Assests/OurClintImage/Picture6.png'


const OurClint = () => {
    var settings = {
        dots: true,
        autoplay:true,
        autoplaySpeed:3000,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='container py-5'>
            <div className='our-clint'>
                <h2 className='mb-5 head-color'> WE ARE COMFORT TO WORK WITH </h2>
                <Slider {...settings}>
                    <div className='our-clint-inner'>
                        <img className='m-auto' src={img1} alt=""/>
                    </div>
                    <div className='our-clint-inner'>
                        <img className='m-auto' src={img2} alt=""/>
                    </div>
                    <div className='our-clint-inner'>
                        <img className='m-auto' src={img3} alt=""/>
                    </div>
                    <div className='our-clint-inner'>
                        <img className='m-auto' src={img4} alt=""/>
                    </div>
                    <div className='our-clint-inner'>
                        <img className='m-auto' src={img5} alt=""/>
                    </div>
                    <div className='our-clint-inner'>
                        <img className='m-auto' src={img6} alt=""/>
                    </div>
                </Slider>
            </div>

        </div>
    );
};

export default OurClint;
