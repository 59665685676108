import React, { useState } from "react";
import logo from "../../../Assests/logo.png";
import line from "../../../Assests/Line 6.png";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";

const Contact = () => {
  const [input, setInput] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleInput = (e) => {
    setInput((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    console.log(input);
  };

  const sendEmail = () => {
    setIsLoading(true);
    axios.post("http://localhost:8000/api/send-email", input).then((res) => {
      Swal.fire({
        position: "bottom-end",
        icon: "success",
        padding: "10px",
        title: res.data.msg,
        toast: true,
        showConfirmButton: false,
        timer: 5000,
      });
      setInput({});
      $("#reset_form").trigger("click");
      $("#text_area").val("");
      setIsLoading(false);
    });
  };

  return (
    <div>
      <div className="contact-banner-section">
        <div className="contact-banner-overlay"></div>
        <div className="contact-us-title">
          <h1>Get in Touch</h1>
        </div>
      </div>
      <section>
        <div className="container">
          <div className="row align-items-center  py-5">
            <div className="col-lg-6">
              <div className="col-lg-6">
                <div className="office-address">
                  <img className="img-fluid" src={logo} alt="" />
                  <img className="img-fluid" src={line} alt="" />
                </div>
                <address className="pt-2">
                  <p className="address-title">DHAKA OFFICE : </p>
                  <i className="fas fa-home mr-3"></i> House: # 30, Road: # 8,
                  Block: # F, Banasree, Dhaka-1219, Bangladesh.
                  <p>
                    <i className="fas fa-envelope mr-3"></i>{" "}
                    sales.dhaka@rybengineering.com
                  </p>
                  <p>
                    <i className="fas fa-phone mr-3"></i> + 88 01515602238
                  </p>
                </address>
                <address className="pt-2">
                  <p className="address-title">Chattogram Office : </p>
                  <i className="fas fa-home mr-3"></i> House # 6, Road # 1,
                  Block # H,
                  <br />
                  Halishahar, Chattogram, Bangladesh.
                  <p>
                    <i className="fas fa-envelope mr-3"></i>{" "}
                    sales.chattogram@rybengineering.com
                  </p>
                  <p>
                    <i className="fas fa-phone mr-3"></i> + 88 01915842284
                  </p>
                </address>
              </div>
            </div>
            <div className="col-lg-6">
              <section className="contact_section">
                <div className="heading  my-4">
                  <h2 className="contact-form-title">
                    We'd Like to Hear From You!
                  </h2>
                </div>
                <div className="row">
                  <div className="col-md mb-4">
                    <form className="row">
                      <div className="col-md-6 mb-4">
                        <input
                          type="text"
                          placeholder="First Name"
                          className="form-control"
                          name={"fname"}
                          value={input.fname}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="col-md-6 mb-4">
                        <input
                          type="text"
                          placeholder="Last Name"
                          className="form-control"
                          name={"lname"}
                          value={input.lname}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="col-md-12 mb-4">
                        <input
                          type="email"
                          placeholder="Your Email"
                          className="form-control"
                          name={"email"}
                          value={input.email}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="col-md-8 mb-4">
                        <input
                          type="text"
                          placeholder="Your phone"
                          className="form-control"
                          name={"phone"}
                          value={input.phone}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="col-md-12 mb-4">
                        <textarea
                          rows="5"
                          placeholder="Your Massage"
                          className="form-control"
                          id={"text_area"}
                          name={"message"}
                          value={input.message}
                          onChange={handleInput}
                        >
                          {input.message}
                        </textarea>
                      </div>
                      <div className="col-md-12 text-center ">
                        <button
                          disabled={isLoading}
                          onClick={sendEmail}
                          type={"button"}
                          className="btn send-mail-btn w-100"
                          dangerouslySetInnerHTML={{
                            __html: isLoading
                              ? '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Mail Sending...'
                              : "Send Mail",
                          }}
                        ></button>
                        <button
                          className={"d-none"}
                          type={"reset"}
                          id={"reset_form"}
                        ></button>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="map">
              <div className="mapouter">
                <div className="gmap_canvas map-responsive">
                  <iframe
                    title="ryb"
                    style={{
                      filter: "invert(90%)",
                      width: "100%",
                      height: "500px",
                    }}
                    id="gmap_canvas"
                    src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3651.6805181841287!2d90.4155227154316!3d23.758769594372396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sHouse%3A%2022%2C%20road%3A%2004%2C%20dit%20project%2C%20merul%20badda%2C%20gulshan%2C%20dhaka-121!5e0!3m2!1sen!2sbd!4v1677960014488!5m2!1sen!2sbd"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
