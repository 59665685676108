import React from 'react';
import AboutBanner from "./AboutBanner/AboutBanner";
import AboutContent from "./AboutContent/AboutContent";

const About = () => {
    return (
        <div>
            <AboutBanner></AboutBanner>
            <AboutContent></AboutContent>
        </div>
    );
};

export default About;
