import React from 'react';

const Introduction = () => {
    return (
        <div className='bg-light py-5'>
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-8">
                        <div className="flex-item">
                                <div className="intro-img-overlay">

                                </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="flex-item-inner">
                            {/*<p>We "RYB Engineering" are very pleased to*/}
                            {/*    introduce ourselves as a premium quality*/}
                            {/*    Supplier, Manufacturer, Consultancy and*/}
                            {/*    Installation & Erection Service Provider.</p>*/}
                            {/*<p>All the entrepreneurs of "RYB Engineering" are*/}
                            {/*    young but well experienced engineers having 8-12*/}
                            {/*    years of experience of rendering their services in*/}
                            {/*    the power sector through renowned and leading*/}
                            {/*    power engineering companies.</p>*/}
                            {/*<p>We "RYB Engineering" are also pleased to*/}
                            {/*    introduce as a well equipped team of qualified &*/}
                            {/*    experienced electrical engineers, involved in*/}
                            {/*    power engineering sector.</p>*/}
                            {/*<p>The main activity of "RYB Engineering" is*/}
                            {/*    Substation, Generator, Busbar Trunking System*/}
                            {/*    (BBT), Cable Tray & Cable Ladder, Lightning*/}
                            {/*    Protection System (LPS), Industrial Lighting, All*/}
                            {/*    Kinds of Electrical Panels, LV Electrical Spares*/}
                            {/*    Supply, Solar System, Electrical and Fire Safety*/}
                            {/*    Consultancy, Fire Detection and Protection*/}
                            {/*    System.</p>*/}
                            <p className=''>
                                Every journey in every
                                business begins with the
                                first step that can be the
                                most difficult at times.
                                Regardless of the size of
                                the first step that we take,
                                if we do it well, it may soon
                                become the step that will
                                take us soaring for greater
                                heights. With this objective
                                in mind, "RYB Engineering"
                                start their journey in
                                February, 2022.

                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Introduction;
