import React from 'react';
import {NavLink} from "react-router-dom";
import logo from '../../../../Assests/logo.png'


const Header = () => {
    return (

        <nav className="navbar navbar-expand-lg custom-bg sticky-top">
            <div className="container">
                <NavLink className="navbar-brand" to="/">
                    <img src={logo} alt=""/>
                </NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar"
                     aria-labelledby="offcanvasNavbarLabel">
                    <div className="offcanvas-header">
                        <NavLink className="navbar-brand" to="/">
                            <img src={logo} alt=""/>
                        </NavLink>
                        <button type="button" className="btn-close bg-white" data-bs-dismiss="offcanvas"
                                aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3" data-bs-dismiss="offcanvas">
                            <li className="nav-item">
                                <NavLink className="nav-link" aria-current="page" to="/">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="about">About</NavLink>
                            </li>
                            <li className="nav-item">

                                <NavLink className="nav-link" to="product-and-service">Product & Service</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="news-and-download">News & Download</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="contact">Contact</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="https://webmail.logix.in/">Webmail</NavLink>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;
